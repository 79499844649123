import { ptBr as addCompany } from '@/common/components/addCompanyModal/locales';
import { ptBr as companyGroup } from '@/common/components/companyGroup/locales';
import { ptBr as menu } from '@/common/components/menu/locales';
import { ptBr as automaticTaxInvoiceInbound } from '@/resources/automatic-tax-invoice-inbound/locales';
import { ptBr as integrations } from '@/resources/integrations/locales';
import { ptBr as myCompanies } from '@/resources/my-companies/locales';
import { ptBr as onboarding } from '@/resources/onboarding/locales';
import { ptBr as taskManager } from '@/resources/task-manager/locales';
import { ptBr as digitalCertificates } from '@/resources/digital-certificates/locales';
import { ptBr as profileManagement } from '@/resources/profile-management/locales';

export default {
  'pt-br': {
    ...myCompanies,
    ...addCompany,
    ...companyGroup,
    ...onboarding,
    ...taskManager,
    ...automaticTaxInvoiceInbound,
    ...menu,
    ...integrations,
    ...digitalCertificates,
    ...profileManagement,
    onboarding: {
      stepButtons: {
        back: 'Anterior',
        nextStep: 'Próximo',
        jumpStep: 'Pular etapa',
        finishStep: 'Finalizar',
      },
      start: {
        text: 'Antes de acessar seu Painel Administrativo, precisamos que sua senha de primeiro acesso seja alterada. Após informe alguns dados através de uma rápida configuração…',
        buttonText: 'Vamos lá!',
        support: 'Suporte',
        hello: 'Olá',
        welcome: 'seja bem-vindo à ROIT',
      },
      changePassword: {
        pageTitle: 'Primeiro, você precisa alterar sua senha…',
        pageSubtitle: 'Para garantirmos sua segurança, solicitamos a alteração da senha no 1º acesso *',
        observation: '* Sempre que precisar ou desejar, poderá alterar a sua senha acessando seu perfil, nas informações pessoais.',
        user: 'Usuário',
        passwordHint: 'A senha deve conter 8 ou mais caracteres com uma combinação de letras, números e símbolos.',
        oldPassword: 'Senha Atual',
        newPassword: 'Nova Senha',
        confirmNewPassword: 'Confirmar Nova Senha',
        changePasswordButton: 'Alterar e Continuar',
        msgError: 'Falha na alteração de senha',
        msgPasswordsDontMatch: 'As senhas digitadas não são iguais',
        msgErrorNotEqualPasswords: 'A nova senha precisa ser diferente da senha atual',
        msgSuccess: 'Senha alterada com sucesso!',
        msgWorking: 'Processando a requisição...',
        rules: {
          minCharacter: 'Mínimo de 8 caracteres',
          hasLyrics: 'Possui letras',
          hasNumbers: 'Possui números',
          hasSpecialCharacters: 'Possui caracteres especiais',
        },
      },
      registerResponsible: {
        pageTitle: 'Vamos cadastrar os responsáveis para o(s) produto(s)',
        pageSubtitle: 'As pessoas cadastradas, receberão convites por e-mail para acessar o(s) produto(s)',
        iBeResponsible: 'Eu serei o(a) responsável pelos produtos',
        newResponsible: 'Cadastrar Responsável',
        clickToRegister: 'Toque no botão acima para cadastrar Responsáveis',
        form: {
          name: 'Nome',
          surname: 'Sobrenome',
          document: 'CPF',
          mail: 'E-mail',
          job: 'Cargo',
          namePlaceholder: 'Ex.: Marcelo',
          surnamePlaceholder: 'Ex.: Silva',
          documentPlaceholder: 'Ex.: 000.000.000-00',
          mailPlaceholder: 'Ex.: e-mail@empresa.com.br',
          jobPlaceholder: 'Ex.: Analista Contábil',
        },
      },
      registerCompany: {
        btnRegister: 'Cadastrar Empresa',
        pageTitle: 'Agora, vamos cadastrar sua(s) empresa(s)',
        pageSubtitle: 'Cadastre sua(s) empresa(s), informando todos os CNPJ’s do seu grupo empresarial, estejam as empresas relacionadas ou não societariamente:',
        clickToRegister: 'Toque no botão acima para cadastrar sua(s) Empresa(s)',
        table: {
          listMsgError: 'Não foi possível encontrar as empresa cadastradas',
          successRegistration: 'Empresa(s) cadastrada(s) com sucesso!',
          errorRegistration: 'Erro(s) reportado(s): {errors}',
          errorInRegistration: 'Não foi possível cadastrar um ou mais CNPJs',
          successUpdate: 'Dados da empresa atualizados com sucesso!',
          errorUpdate: 'Não foi possível atualizar os dados da empresa',
          successDelete: 'Empresa(s) excluída(s) com sucesso!',
          errorDelete: 'Não foi possível excluir a empresa',
          errorSearch: 'Não foi possível fazer a busca',
        },
        confirmDelete: {
          title: 'Excluir Empresa',
          text: 'Confirma a exclusão desta Empresa?',
        },
        titleMessageError: 'Os seguintes erros foram reportados:',
      },
      integrations: {
        pageTitle: 'Possui algum Sistema de Integração?',
        pageSubtitle: 'Precisamos fazer a integração com o seu ERP e/ou com os Softwares que você utiliza:',
        registerSystems: 'Cadastrar Sistemas',
        clickToRegister: 'Toque no botão acima para cadastrar um Sistema de Integração',
        modal: {
          title: 'Cadastrar Sistema de Integração',
          btnCancel: 'Cancelar',
          btnRegister: 'Cadastrar',
          btnUpdate: 'Atualizar',
          form: {
            selectCompaniesLabel: 'Selecione as empresas para cadastrar o sistema de integração:',
            selectCompaniesPlaceHolder: 'Selecione as empresas',
            system: 'Sistema',
            systemLabel: 'Selecione um Sistema',
            systemPlaceholder: 'Ex.: Questor,Protheus...',
            version: 'Versão',
            versionPlaceholder: 'Ex.: 1.12.5',
            token: 'Token',
            integrationToken: 'Token de integração',
            integrationTokenPlaceholder: 'Ex.: 2222222',
            user: 'Usuário',
            userPlaceholder: 'Usuário',
            password: 'Senha',
            passwordPlaceholder: 'Informe a Senha',
            ip: 'Endereço IP',
            ipPlaceholder: 'Ex.: 00.000.000.00',
            port: 'Porta',
            portPlaceholder: 'Ex.: 8080',
            notOwn: 'Não possuo',
            informAfter: 'Informar depois',
            selectFinalities: 'Selecione a(s) finalidade(s)',
            companyDb: 'Esquema do banco',
            companyDbPlaceHolder: 'Ex.: BANCO',
            retryLabel: 'Em caso de falha na integração, reiniciar o processo a cada:',
            retryPlaceholder: 'Ex.: 01:30',
            optionsLabel: 'Conexão com banco de dados',
          },
          msgCreateSuccess: 'Sistema de Integração inserido com sucesso',
          msgCreatError: 'Não foi possível inserir o Sistema de Integração',
          msgUpdateSuccess: 'Sistema de Integração atualizado com sucesso',
          msgUpdateError: 'Não foi possível atualizar o Sistema de Integração',
        },
        headers: {
          systems: 'Sistemas',
          version: 'Versão',
          integrationToken: 'Token de integração',
          user: 'Usuário',
          password: 'Senha',
          ipAndPort: 'IP / Porta',
          finality: 'Finalidade',
        },
        body: {
          tooltip: {
            delete: 'Excluir',
            edit: 'Editar',
          },
        },
        confirmation: {
          title: 'Excluir Sistema de Integração',
          message: 'Confirme a exclusão do Sistema de Integração.',
          msgSuccess: 'Sistema de Integração excluída com sucesso.',
          msgError: 'Não foi possível excluír o Sistema de Integração.',
        },
      },
      finish: {
        message: 'Configuração finalizada com sucesso!',
        buttonTextPt1: 'Acessar meu Painel',
        buttonTextPt2: 'ROIT Admin',
      },
    },
    onboardingBank: {
      returnAdmin: 'Voltar ao ROIT Admin',
      downloadCsvInfo: 'O download iniciará em breve',
      downloadCsvError: 'Não foi possível realizar o download',
      start: {
        hello: 'Olá',
        greetings: 'O futuro chegou com a ROIT!',
        message: 'A partir de agora, mostraremos como realizar as configurações para obter as melhores experiências e ótimos resultados com a Esteira de HIPERAUTOMAÇÃO ROIT!',
        buttonText: 'Vamos começar?',
        support: 'Suporte',
      },
      accountantRobot: {
        csv: {
          account: 'Modelo_PLANO_DE_CONTAS.csv',
          product: 'Modelo_LANCAMENTOS_PRODUTOS.csv',
          services: 'Modelo_LANCAMENTOS_SERVICOS.csv',
        },
        text: {
          how: 'Como configurar o Robô Contábil:',
          first: 'Da mesma forma que o Robô fiscal, vamos enriquecer o Robô Contábil. Isso ajudará a nossa Inteligência Artificial para encontrar as melhores oportunidades de classificações para cada documento que passa por sua empresa.',
          second: 'Nesse caso, as informações serão adicionadas em três documentos - que também deixamos arquivos modelos para download. O primeiro deles é o de Plano de Contas e os seguintes são para adicionar os Lançamentos de produtos e serviços.',
          third: 'Após o preenchimento, basta importar. Esses dados também poderão ser validados posteriormente!',
        },
        modelAccount: {
          header: 'Como Importar o Plano de Contas',
          description: 'Confira abaixo as tabelas que devem ser extraídas do seu ERP para realizar a importação no BANK e enriquecer o Robô Contábil.',
          closeButton: 'Fechar',
          downloadButton: 'Modelo Plano de Contas CSV',
          CTBAccount: {
            title: 'CONTACTB',
            type: 'string',
            description: 'Conta contábil, número entre 1-99999',
          },
          accountType: {
            title: 'TIPOCONTA',
            type: 'string',
            description: '1 para Sintética, 2 para Analítica',
          },
          accountClassification: {
            title: 'CLASSIFCONTA',
            type: 'string',
            description: 'Classificação da conta. Ex.: 1 ou 1.1 ou 1.1.01 ou 1.1.01.001',
          },
          accountDescription: {
            title: 'DESCRCONTA',
            type: 'string',
            description: 'Deve conter a descrição da conta. Ex.: Caixa Geral',
          },
          CNPJInt: {
            title: 'CNPJINT',
            type: 'string',
            description: 'CNPJ com 14 dígitos. Ex.: 12345678000112',
          },
        },
        productLaunch: {
          header: 'Como Importar os Lançamentos de Produtos',
          description: 'Confira abaixo as tabelas que devem ser extraídas do seu ERP para realizar a importação no BANK e enriquecer o Robô Contábil.',
          closeButton: 'Fechar',
          downloadButton: 'Modelo Lançamento Produtos CSV',
          CNPJOrigin: {
            title: 'CNPJORIG',
            type: 'string',
            description: 'CNPJ com 14 dígitos. Ex.: 12345678000112',
          },
          CNPJDestination: {
            title: 'CNPJDEST',
            type: 'string',
            description: 'CNPJ com 14 dígitos. Ex.: 12345678000112',
          },
          NCMCode: {
            title: 'CODIGONCM',
            type: 'string',
            description: 'Deve conter 8 números e pode ser separado por pontos. Ex.: 1902.20.00',
          },
          CFOPCode: {
            title: 'CODIGOCFOP',
            type: 'string',
            description: 'Código CFOP com 4 dígitos. Ex.: 1556',
          },
          unitaryValue: {
            title: 'VALORUNITARIO',
            type: 'float',
            description: 'Valor unitário. Ex.: 125.15',
          },
          bookValue: {
            title: 'VALORCONTABIL',
            type: 'float',
            description: 'Valor unitário. Ex.: 125.15',
          },
          debitAccount: {
            title: 'CONTACTBDEB',
            type: 'string',
            description: 'Conta contábil de débito número entre 1-999999. Ex.:4254',
          },
        },
        serviceLaunch: {
          header: 'Como Importar os Lançamentos de Serviços',
          description: 'Confira abaixo as tabelas que devem ser extraídas do seu ERP para realizar a importação no BANK e enriquecer o Robô Contábil.',
          closeButton: 'Fechar',
          downloadButton: 'Modelo Lançamentos Serviços CSV',
          bookValue: {
            title: 'VALORCONTABIL',
            type: 'float',
            description: 'Valor unitário. Ex.: 214.04',
          },
          CNPJOrigin: {
            title: 'CNPJORIG',
            type: 'string',
            description: 'CNPJ com 14 dígitos. Ex.: 12345678000112',
          },
          CNPJDestination: {
            title: 'CNPJDEST',
            type: 'string',
            description: 'CNPJ com 14 dígitos. Ex.: 12345678000112',
          },
          debitAccount: {
            title: 'CONTACTBDEB',
            type: 'string',
            description: 'Conta contábil de débito número entre 1-999999. Ex.:4254',
          },
          serviceCode: {
            title: 'CODIGOSERVICO',
            type: 'string',
            description: 'Código de serviço com 4 dígitos. Ex.: 0101',
          },
        },
      },
      taxRobot: {
        csv: {
          companies: 'Modelo_EMPRESAS.csv',
        },
        text: {
          how: 'Como funciona o Robô Fiscal:',
          first: 'Enriquecer o Robô Fiscal permite o treino das redes neurais para entregar as melhores Regras Fiscais para os cenários da sua empresa.',
          second: 'Sendo assim, precisaremos que você importe os dados de sua empresa em um modelo que disponibilizamos abaixo. Para que ao importá-lo na configuração tudo ocorra da melhor forma possível.',
          third: 'Não se preocupe! Será disponibilizada uma área onde você poderá realizar uma validação com um pequeno trecho desde arquivo.',
        },
        modelCompanies: {
          header: 'Como Importar o Modelo Empresas',
          description: 'Confira abaixo as tabelas que devem ser extraídas do seu ERP para importar no BANK e enriquecer o Robô Fiscal',
          closeButton: 'Fechar',
          downloadButton: 'Modelo Empresas CSV',
          cdModel: {
            title: 'CDMODELO',
            type: 'string',
            description: 'Modelo do documento fiscal vindo do xml, usar 99 para serviço',
          },
          referringItem: {
            title: 'ITEMREFERENTE',
            type: 'string',
            description: 'Código NCM com 8 dígitos ou serviço com 4 dígitos. Ex.: 04051000, 1719, etc',
          },
          taxRegime: {
            title: 'REGIMETRIBINT',
            type: 'integer',
            regimes: {
              first: '1 - Regime Simples Nacional',
              second: '2 - Regime Lucro Real',
              third: '3 - Regime Lucro Presumido',
            },
          },
          movementType: {
            title: 'TIPOMOVIMENTO',
            type: 'string',
            description: '"E" para Entrada ou "S" para Saída',
          },
          operationType: {
            title: 'TIPOMOVIMENTO',
            type: 'string',
            description: 'Produto ou Serviço',
          },
          CNAEDestination: {
            title: 'CNAEDEST',
            type: 'string',
            description: 'CNAE primário do tomador/adquirent com 7 dígitos. EX: 4712100',
          },
          CNAEOrigin: {
            title: 'CNAEORIG',
            type: 'string',
            description: 'CNAE primário do prestador/vendedor com 7 dígitos. EX: 4712100',
          },
          CFOPCode: {
            title: 'CODIGOCFOP',
            type: 'string',
            description: 'Código CFOP com 4 dígitos, para serviço usar CFOP 9999. Ex: 1653, 1407',
          },
          IBGEDestinationCode: {
            title: 'CODIGOIBGEDEST',
            type: 'string',
            description: 'Código IBGE do município de destino',
          },
          IBGEOriginCode: {
            title: 'CODIGOIBGEORIG',
            type: 'string',
            description: 'Código IBGE do município de origem',
          },
          taxpayer: {
            title: 'CONTRIBUINTE',
            type: 'string',
            options: {
              description: 'Se o destino é contribuinte ou não use:',
              first: '0 - Para não',
              second: '1 - Para sim',
              third: '2 - Para não se aplica',
            },
          },
          acronymStateDestination: {
            title: 'SIGLAESTADODEST',
            type: 'string',
            description: 'UF do estado destino',
          },
          acronymStateOrigin: {
            title: 'SIGLAESTADOORIG',
            type: 'string',
            description: 'UF do estado origem',
          },
          withheldINSSAliquot: {
            title: 'ALIQINSSRET',
            type: 'float',
            description: 'Alíquota aplicada no imposto retido INSS com duas casas decimais. Ex.: 11.00',
          },
          withheldCSLLAliquot: {
            title: 'ALIQCSLLRET',
            type: 'float',
            description: 'Alíquota aplicada no imposto retido CSLL com duas casas decimais. Ex.: 1.00',
          },
          withheldPISAliquot: {
            title: 'ALIQPISRET',
            type: 'float',
            description: 'Alíquota aplicada no imposto retido PIS com duas casas decimais. Ex.: 0.65',
          },
          withheldCOFINSAliquot: {
            title: 'ALIQCOFINSRET',
            type: 'float',
            description: 'Alíquota aplicada no imposto retido COFINS com duas casas decimais. Ex.: 3.00',
          },
          withheldISSQNAliquot: {
            title: 'ALIQISSQNRET',
            type: 'float',
            description: 'Alíquota aplicada no imposto retido ISSQN com duas casas decimais. Ex.: 2.50',
          },
          PISAliquot: {
            title: 'ALIQPIS',
            type: 'float',
            description: 'Alíquota aplicada para PIS com duas casas decimais. Ex.: 0.65',
          },
          CSTPIS: {
            title: 'CSTPIS',
            type: 'string',
            description: 'Código da situação tributária referente a PIS',
          },
          COFINSAliquot: {
            title: 'ALIQCOFINS',
            type: 'float',
            description: 'Alíquota aplicada para COFINS usando valor fracionário com duas casas. EX.: 0.65',
          },
          CSTCOFINS: {
            title: 'CSTCOFINS',
            type: 'string',
            description: 'Código da situação tributária referente a COFINS',
          },
          IPIAliquot: {
            title: 'ALIQIPI',
            type: 'float',
            description: 'Alíquota aplicada para IPI com duas casas decimais. Ex.: 25.00',
          },
          CSTIPI: {
            title: 'CSTIPI',
            type: 'string',
            description: 'Código da situação tributária referente a IPI',
          },
          ICMSAliquot: {
            title: 'ALIQICMS',
            type: 'float',
            description: 'Alíquota aplicada para ICMS usando valor fracionário com duas casas. EX.: 18.00',
          },
          ICMSInterAliquot: {
            title: 'ALIQICMSINTER',
            type: 'float',
            description: 'Alíquota interestadual aplicada na operação usando valor fracionário. Ex.: 12.00',
          },
          CSTICMS: {
            title: 'CSTICMS',
            type: 'string',
            description: 'Código da situação tributária referente a ICMS',
          },
          MVAPAUTA: {
            title: 'MVAPAUTA',
            type: 'float',
            description: 'Valor de MVA ou Pauta aplicado com duas casas decimais. Ex.: 129.40',
          },
          NQIPICode: {
            title: 'CODENQIPI',
            type: 'string',
            description: 'Código de enquadramento legal de IPI',
          },
          ICMSAliquotReduction: {
            title: 'ALIQICMSRED',
            type: 'float',
            description: 'Percentual de redução da base de cálculo com quatro casas decimais. Ex.: 41.1465',
          },
          ICMSAliquotDiff: {
            title: 'ALIQICMSDIF',
            type: 'float',
            description: 'Diferimento da base de calculo com quatro casas decimais. Ex.: 60.0000',
          },
          withheldIRRFAliquot: {
            title: 'ALIQIRRFRET',
            type: 'float',
            description: 'Alíquota aplicada do imposto retido IRRF com duas casas decimais. Ex.: 1.50',
          },
        },
      },
    },
    configHomeScreen: {
      start: {
        hello: 'Vamos agora iniciar as configurações que irão revolucionar sua contabilidade?',
        ready: {
          firstNormal: 'Agora que você já sabe como funcionam os ',
          secondBold: 'Robôs Fiscal e Contábil, ',
          thirdNormal: 'é hora de configurar!',
        },
        message: {
          firstNormal: 'Lembre-se de utilizar os ',
          secondBold: 'arquivos modelos ',
          thirdNormal: 'e também de acessar as sessões de validação, em que você pode realizar pequenos testes para evitar inconsistências.',
        },
        technology: {
          firstNormal: 'Com o auxílio da ',
          secondBold: 'Inteligência Artificial ',
          thirdNormal: 'e os processos mapeados, conseguiremos oferecer uma experiência incrível. Vamos começar?',
        },
        buttonText: 'Acessar e configurar',
      },
    },
    welcomeVideo: {
      start: {
        title: {
          normal: 'Entenda o ',
          bold: 'fluxo invertido da contabilidade e gestão financeira!',
        },
        message: 'Com a inteligência artificial, automatizamos etapas e construimos um processo com informações auditadas em tempo real para uma gestão estratégica.',
        stepText: 'Vídeo Instrutivo',
        buttonText: 'Próximo',
      },
    },
    import: 'Importar',
    requiredField: 'Campo obrigatório',
    notInformed: 'Não informado',
    date: 'O campo não é válido',
    ascii: 'O campo {_field_} deve conter apenas caracteres ASCII',
    ip: 'O campo {_field_} deve ser um IP válido',
    port: 'O campo {_field_} deve ser uma Porta válida',
    email: 'E-mail',
    cpom: 'CPOM',
    yes: 'Sim',
    no: 'Não',
    sn: 'Optante SN',
    mei: 'MEI',
    irs: 'Situação na Receita Federal',
    supplier: 'Prestador',
    taker: 'Tomador',
    iss: 'ISS',
    irrf: 'IRRF',
    csll: 'CSLL',
    cofins: 'COFINS',
    pis: 'PIS',
    inss: 'INSS',
    aliquot: 'Alíquota (%)',
    value: 'Valor',
    active: 'Ativo',
    inactive: 'Inativo',
    pending: 'Aguardando pagamento',
    paid: 'Pago',
    overdue: 'Vencido',
    state: 'Estado',
    city: 'Município',
    file: 'Arquivo',
    establishment: 'Estabelecimento',
    categorization: 'Categorização',
    competence: 'Competência',
    error: 'Erro',
    success: 'Sucesso',
    fail: 'Falha',
    processing: 'Em processamento',
    inssValue: 'Valor INSS',
    quantity: 'Quantidade',
    add: 'Adicionar',
    totalValue: 'Valor Total',
    bankingData: 'Dados Bancários',
    bank: 'Banco',
    operation: 'Operação',
    agency: 'Agência',
    account: 'Conta',
    demandant: 'Demandante',
    back: 'voltar',
    cancel: 'Cancelar',
    redefine: 'Redefinir',
    interrogation: '?',
    pay: 'Pagar',
    invalidCNPJ: 'CNPJ Inválido',
    attention: 'Atenção',
    proceed: 'Prosseguir',
    select: 'Selecione',
    regular: 'Regular',
    irregular: 'Irregular',
    nonDeductible: 'Indedutível',
    search: 'Busca',
    delete: 'Excluir',
    disable: 'Desabilitar',
    disabled: 'Desabilitado',
    deleteAll: 'Excluir todos',
    inactivateAll: 'Desabilitar todos',
    selectAll: 'Selecionar Todas',
    register: 'Cadastrar',
    warning: 'Atenção',
    save: 'Salvar',
    edit: 'Editar',
    info: 'Informação',
    confirm: 'Confirmar',
    authorize: 'Autorizar',
    remove: 'Remover',
    maxUpload: 'Máximo de {max} upload(s)',
    update: 'Atualizar',
    certificate: 'Certificado',
    password: 'Senha',
    invalidCreditCard: 'Cartão de Crédito inválido',
    invalidDomain: 'Dominio inválido',
    requiredChips: 'Campo obrigatório. Após digitar o valor pressione Enter',
    invalidCreditCardWithFlags: 'Cartão de Crédito inválido.<br/><u>Bandeiras permitidas</u>: {allowedFlags}',
    rating: 'Avaliação',
    commentary: 'Comentário',
    close: 'Fechar',
    pause: 'Pausar',
    paused: 'Pausado',
    run: 'Executar',
    historic: 'Histórico',
    exclude: 'Excluir',
    resume: 'Retomar',
    invalidSpecificHour: 'A hora deve ter o seguinte formato 00:00',
    invalidCron: 'Formato inválido. Verifique a ajuda ao lado.',
    and: 'e',
    username: 'Usuário',
    reprocessCPOMMessage:
      'CPOM precisa ser reprocessado! <br/> Esta tela será fechada, acesse este documento novamente em instantes.',
    disableUser: 'Desabilitar usuário',
    errorStates: 'Não foi possível buscar os estados',
    validation: 'Validação',
    noResultsFound: 'Nenhum resultado encontrado',
    hour: 'hora',
    hours: 'horas',
    minutes: 'minutos',
    every: 'A cada',
    name: 'Nome',
    location: 'Localização',
    MyProductsTemplate: {
      myProducts: 'Meus Produtos',
      responsibles: 'Gerenciar Responsáveis',
    },
    MyProductsPage: {
      check: 'Confira seus produtos contratados:',
      tooltip: 'Contratos e<br> Termos Aditivos',
      redirectText: 'Acessar Site',
      email: {
        tooltip: 'Documentos por e-mail',
        modal: {
          header: {
            register: 'Cadastro de e-mail ou domínio',
            edit: 'Editar e-mail ou domínio',
          },
          description: 'Cadastre os e-mails/domínios responsáveis pelos recebimentos dos documentos. Os documentos que chegarem neste endereço serão direcionados à Esteira de HIPERAUTOMAÇÃO ROIT.',
          domainLabel: 'Domínio',
          emailLabel: 'E-mail',
          placeholder: 'Digite um e-mail ou domínio por vez',
          requestError: 'Não foi possível fazer o cadastro.',
          requestFieldsError: 'Selecione uma opção e preencha o campo.',
          requestSucess: 'Cadastro realizado com sucesso.',
          alredyExistsError: 'E-mail ou dominio já cadastrado!',
          type: 'Tipo',
          address: 'Endereço',
          roitEmailError: 'Não é permitido o cadastro do e-mail: ',
        },
      },
      configButton: 'Configurações Iniciais',
      discover: {
        message: 'Para utilizar o <b>{field}</b>, precisamos configurá-lo em poucas etapas, Vamos lá?',
        btnStart: 'Iniciar Configuração',
        btnDiscover: 'Quero conhecer',
        products: {
          bank: 'Com a <b>ROIT</b> unificamos a contabilidade, a gestão fiscal e a gestão financeira com processos automatizados e inteligência artificial para facilitar o dia a dia da sua empresa!',
          people: 'O <b>ROIT People</b> traz soluções integradas para sua empresa, com a gestão de pessoas com um app que conecta RH, gestores e colaboradores',
        },
      },
      headers: {
        module: 'Módulo',
        quantity: 'Qtde Contratada',
        validity: 'Vigência',
        plan: 'Plano',
        status: 'Status',
      },
      actions: {
        contractsAndAdditiveTerms: {
          msgError: 'Erro ao consultar as informações contratuais',
        },
        findProducts: {
          msgError: 'Erro ao consultar informações dos produtos',
        },
      },
      cancelProduct: {
        title: 'Cancelamento de Produto',
        description: 'Gostaríamos de entender um pouco mais o motivo do cancelamento do módulo {field}, Nós já acionamos nosso suporte que entrará em contato com você em breve ;)',
        questionRatingProduct: 'Enquanto isso, poderia nos ajudar avaliando o quanto satisfeito você está com esse módulo?',
        questionCommentary: 'Gostaria de nos deixar algum comentário?',
        sendMessage: 'Enviar',
        successFeedback: 'Em breve entraremos em contato para finalizarmos sua solicitação',
        veryUnsatisfied: 'Muito insatisfeito',
        verySatisfied: 'Muito satisfeito',
        actions: {
          msgSuccess: 'Solicitação enviada com sucesso',
          msgError: 'Erro ao solicitar cancelamento do módulo',
        },
        cancelRequest: 'Solicitar Cancelamento',
      },
    },
    ManagerResponsible: {
      title: 'Cadastre e gerencie os responsáveis pelos produtos.',
      subtitle: 'As pessoas cadastradas receberão os convites por e-mail para poder acessá-los.',
      new: 'Cadastrar Responsável',
      headers: {
        responsible: 'Responsável',
        mail: 'E-mail',
        job: 'Cargo',
        products: 'Produtos',
      },
      addNewResponsible: {
        msgSuccess: 'Responsável registrado com sucesso',
        msgError: 'Erro ao cadastrar o responsável',
      },
      updateUserAdmin: {
        msgSuccess: 'Registro de responsável atualizado com sucesso',
        msgError: 'Erro ao atualizar o cadastro do responsável',
      },
      deleteResponsible: {
        actionTitle: 'Excluir Responsável',
        actionMessage: 'Deseja excluir o responsável abaixo ?',
        msgSuccess: 'Responsável excluído com sucesso',
        msgError: 'Erro ao excluir o responsável',
      },
    },
    registerCompanyTable: {
      headers: {
        cnpj: 'CNPJ',
        razaoSocial: 'Razão Social',
        city: 'Cidade',
        uf: 'UF',
        status: 'Status RFB',
        ie: 'Inscrição Estadual',
        im: 'Inscrição Municipal',
        rt: 'Regime Tributário',
        erpId: 'ID ERP',
        polling: 'Apuração',
        retreat: 'Recolhimento',
      },
    },
    noRecordsInDataTable: 'Sem registros',
    users: {
      index: {
        title: 'Cadastre e gerencie usuários para acesso aos produtos',
        companySelectLabel: 'Selecione um grupo para ver os usuários',
        companySelect: 'Selecione uma empresa',
        newRegister: 'Cadastrar Usuários',
        dropdown: {
          msgError: 'Não foi possível buscar as Empresas',
        },
        products: {
          msgError: 'Não foi possível buscar os produtos',
        },
        actions: {
          findUsers: {
            msgError: 'Não foi possível buscar os usuários',
            msgWarning: 'Uma empresa deve ser selecionada para buscar seus usuários',
          },
          disable: {
            one: {
              title: 'Desabilitar usuário',
              message: 'Deseja desabilitar este usuário?',
              msgError: 'Não foi possível desabilitar o usuário',
              msgSuccess: 'Usuário desabilitado com sucesso',
            },
            many: {
              title: 'Desabilitar usuários',
              message: 'Deseja desabilitar os usuários selecionados?',
              msgError: 'Não foi possível desabilitar os usuários',
              msgSuccess: 'Usuários desabilitados com sucesso',
            },
          },
          enable: {
            one: {
              title: 'Habilitar usuário',
              message: 'Deseja habilitar este usuário?',
              msgError: 'Não foi possível habilitar o usuário',
              msgSuccess: 'Usuário habilitado com sucesso',
            },
            many: {
              title: 'Habilitar usuários',
              message: 'Deseja habilitar os usuários selecionados?',
              msgError: 'Não foi possível habilitar os usuários',
              msgSuccess: 'Usuários habilitados com sucesso',
            },
          },
          save: {
            msgSuccess: 'Usuário registrado com sucesso',
            msgError: 'Não foi possível registrar o usuário',
            alreadyRegistered: 'Usuário já cadastrado',
          },
          update: {
            msgSuccess: 'Dados do usuário atualizado com sucesso',
            msgError: 'Não foi possível atualizar os dados do usuário',
          },
          importUser: {
            msgSuccess: 'Usuários registrados com sucesso',
            msgError: 'Não foi possível registrar os usuários',
          },
          disableUser: 'Desabilitar usuário',
          enableUser: 'Habilitar usuário',
        },
        updateUser: 'Alterar usuário',
        addUserModal: {
          fields: {
            company: {
              label: 'Vincular grupo ao usuário',
              placeholder: 'Selecione um grupo',
            },
            name: {
              label: 'Nome',
              placeholder: 'Ex: Marcelo',
            },
            surname: {
              label: 'Sobrenome',
              placeholder: 'Ex: Silva',
            },
            occupation: {
              label: 'Cargo',
              placeholder: 'Ex: Analista Fiscal',
            },
            mail: {
              label: 'E-mail',
              placeholder: 'nome@suaempresa.com',
            },
            profile: {
              label: 'Perfil',
              placeholder: 'Selecione os perfis',
              app: 'BANK - APP',
              esteiramagica: 'BANK - Esteira Mágica',
              bankadminuserandcontactsmenu: 'BANKAdmin - Usuários e Contatos',
              bankadminmyproductsmenu: 'BANKAdmin - Meus Produtos',
              bankadminmycompaniesmenu: 'BANKAdmin - Minhas Empresas',
              bankadminintegrationsmenu: 'BANKAdmin - Integrações',
              bankadmindigitalcertificatesmenu: 'BANKAdmin - Certificados Digitais',
              bankadminbillingmenu: 'BANKAdmin - Faturamento',
              bankadminaccesspassmenu: 'BANKAdmin - Senhas de Acesso',
            },
          },
          companySelectLabel: 'Selecione uma empresa para vincular ao usuário',
          tabRegisterUser: 'Cadastrar usuário',
          tabImportUser: 'Importar Usuários (em lote)',
          selectProfile: 'Selecione um perfil',
          btnImportLabel: 'Importar',
          importMessage: 'Para importar usuários, selecione um perfil, baixe o template padrão correspondente, preencha-o e arraste para o campo abaixo',
        },
        usersTable: {
          user: 'Usuário',
          occupation: 'Cargo',
          company: 'Empresa',
          mail: 'E-mail',
          profile: 'Perfil',
          status: 'Status',
        },
        profilesModal: {
          title: 'Perfis do Usuário',
        },
      },
    },
    billing: {
      sections: {
        billing: {
          title: 'Faturamento',
          trackDetailedConsumption: 'Acompanhe o Consumo detalhado, por produto:',
          filterByPeriod: 'Filtro por periodo',
          viewInvoice: 'Visualizar Recibo',
          defaultGroupName: 'Grupo padrão',
          businessGroupTable: {
            headers: {
              cnpj: 'CNPJ',
              product: 'Produto',
              period: 'Período do Consumo',
              plan: 'Plano',
              amountConsumed: 'Qtde Consumida',
              totalValue: 'Valor Total',
              dueDate: 'Vencimento',
              status: 'Status',
              actions: 'Ações',
              emission: 'Emissão',
            },
            sumary: {
              totals: 'Totais',
            },
            text: {
              unitValue: 'Valor unitário: ',
              viewBilling: 'Visualizar fatura',
              viewCard: 'Cartão de Crédito',
              viewInvoice: 'Boleto',
            },
          },
          listBills: {
            msgWarning: 'Não foi possível listar o faturamento',
            msgError: 'Não foi possível listar o faturamento',
          },
          makePayment: {
            msgWarning: 'Não existe boleto disponível para o produto ou período',
            msgError: 'Não foi possível obter o boleto deste grupo',
          },
          getBills: {
            msgWarning: 'Não existe faturamento disponível para o produto ou período',
            msgError: 'Não foi possível obter o arquivo demonstrativo do faturamento deste grupo',
          },
          listBillsByPeriod: {
            msgWarning: 'Não foi possível listar o faturamento por período.',
            msgError: 'Não foi possível listar o faturamento por período.',
          },
          detailModal: {
            title: 'Detalhes da Fatura',
            info: {
              identifier: 'CPF/CNPJ',
              company: 'Empresa',
              product: 'Produto',
              releaseDate: 'Data de Lançamento',
              dueDate: 'Vencimento',
              modality: 'Modalidade',
              amount: 'Total Geral',
            },
            table: {
              product: 'Produto',
              consumed: 'Qtd Consumida',
              price: 'Preço',
              surplus: 'Excedente',
              minimumValue: 'Valor mínimo',
              total: 'Valor Total',
            },
          },
          receiptPayment: {
            title: 'COMPROVANTE DE PAGAMENTO',
            value: 'VALOR',
            attempts: 'TENTATIVAS',
            paymentMethod: 'MODALIDADE DE PAGAMENTO',
            getChargeFail: 'Não foi possível exibir o comprovante',
            cardInfo: {
              title: 'DADOS DO CARTÃO',
              flag: 'BANDEIRA',
              number: 'NÚMERO DO CARTÃO',
              holder: 'PORTADOR',
              validity: 'VALIDADE',
            },
            payment: {
              title: 'PAGAMENTO',
              transaction: 'TRANSAÇÃO',
              installment: 'PARCELAMENTO',
              attempts: 'TENTATIVAS',
              idTransaction: 'ID DE TRANSAÇÃO',
            },
            authorizationCode: 'codigo de autorização',
            callCenter: 'Central de Atendimento ROIT',
            SAC: 'SAC 0800 942 3019',
            ombudsman: 'Ouvidoria ouvidoria@roit.ai',
            Roit: 'ROIT S.A.',
            RoitCNPJ: 'CNPJ: 31.391.902/0001-20',
          },
          summaryPayment: {
            title: 'Resumo para pagamento',
            total: 'Total',
            payByInvoice: 'Pagar por Boleto',
            payByCreditCard: 'Pagar com cartão',
            toPay: 'Pagar',
          },
        },
        billingInformation: {
          title: 'Informações de Cobrança',
          paymentMethod: 'Método de Pagamento',
          billingAddress: 'Endereço de Cobrança',
          invoiceAddress: 'Endereço de Fatura',
          noPaymentMethodRegister: 'Sem métodos de pagamentos cadastrados',
          addPaymentMethod: 'Adicionar Método de Pagamento',
          expiresIn: 'Expira em',
          actions: {
            list: {
              msgError: 'Não foi possível buscar os métodos de pagamentos',
            },
            edit: 'Editar',
          },
          manager: {
            card: {
              name: {
                label: 'Nome',
                title: 'Nome (igual ao impresso no Cartão)',
                placeholder: 'Marcelo Silva',
              },
              number: {
                title: 'Número do Cartão',
                placeholder: '0000 0000 0000 0000',
              },
              expiresIn: {
                title: 'Data de Expiração',
                placeholder: '02/2023',
              },
              securityCode: {
                title: 'Código de Segurança',
                placeholder: '000',
              },
              isMain: 'Usar este cartão como método de pagamento principal',
              addNewCard: 'Adicionar Novo Cartão',
            },
            name: 'Nome',
            surname: 'Sobrenome',
            company: 'Empresa',
            email: 'E-mail',
            postcode: 'CEP',
            searchPostcode: 'Consultar CEP',
            address: 'Endereço',
            addressNumber: 'Número',
            addressInformation: 'Complemento',
            district: 'Bairro',
            city: 'Cidade',
            state: {
              title: 'Estado',
              placeholder: '-',
            },
            country: 'País',
            useTheSameAddressToInvoice: 'Usar o mesmo endereço para Fatura',
            countryName: 'BR',
            actions: {
              save: {
                msgSuccess: 'Método de pagamento salvo com sucesso',
                msgError: 'Não foi possível salvar o método de pagamento',
              },
              update: {
                msgSuccess: 'Método de pagamento atualizado com sucesso',
                msgError: 'Não foi possível atualizar o método de pagamento',
              },
              deleteCreditCard: {
                title: 'Excluir método de pagamento',
                message: 'Deseja excluir esse método de pagamento?',
                msgSuccess: 'Método de pagamento excluído com sucesso',
                msgError: 'Não foi possível excluir o método de pagamento',
              },
            },
          },
        },
      },
    },
    table: {
      mainLabelEmptyFlow: 'Sem Registros',
      secondaryLabelEmptyFlow: 'Sem dados para exibição',
    },
    managerProfile: {
      personalInformations: 'Informações Pessoais',
      userAndPassword: 'Usuário e Senha',
      myAccount: 'Minha Conta',
      changeCover: 'Trocar capa',
      toogleMenu: {
        photoChange: 'Trocar Foto',
        photoDelete: 'Excluir Foto',
        confirmationModal: {
          deleteAvatar: {
            title: 'Excluir foto do Perfil',
            message: 'Confirmar exclusão da foto de perfil?',
          },
        },
      },
      fields: {
        user: {
          name: {
            label: 'Nome',
            placeholder: 'Ex: Marcelo',
          },
          surname: {
            label: 'Sobrenome',
            placeholder: 'Ex: Silva',
          },

          mail: {
            label: 'E-mail',
            placeholder: 'nome@suaempresa.com',
          },
          phone: {
            label: 'Telefone',
            placeholder: 'Ex: (99) 9999-9999',
          },
          cellphone: {
            label: 'Celular',
            placeholder: 'Ex: (99) 99999-9999',
          },
        },
        address: {
          zipcode: {
            label: 'CEP',
            placeholder: 'Ex: 99.999-9999',
          },
          zipcodeConsultation: {
            label: 'Consultar CEP',
            placeholder: 'Ex: 99.999-9999',
          },
          street: {
            label: 'Logradouro',
            placeholder: 'Ex: Rua Jardim América',
          },
          number: {
            label: 'Número',
            placeholder: 'Ex: 309',
          },
          complement: {
            label: 'Complemento',
            placeholder: 'Ex: Apartamento, 2º andar',
          },
          district: {
            label: 'Bairro',
            placeholder: 'Ex: Jardim Paulista',
          },
          city: {
            label: 'Cidade',
            placeholder: 'Ex: Pinheiros',
          },
          state: {
            label: 'Estado',
            placeholder: '',
          },
          country: {
            label: 'País',
            placeholder: 'Ex: Brasil',
          },
        },
        enterprise: {
          cnpj: {
            label: 'CNPJ:',
            placeholder: 'Ex: 99.999.999/9999-99',
          },
          company: {
            label: 'Empresa',
            placeholder: 'Nome da Empresa',
          },
        },
        account: {
          title: 'Usuário e Senha',
          login: {
            label: 'Login',
          },
          password: {
            label: 'Senha',
          },
        },
      },
      getProfile: {
        msgError: 'Não foi possível buscar os dados do perfil',
      },
      updateProfile: {
        msgValidation: 'Existe(m) campo(s) obrigatório(s) ou informação(ões) inválida(s)',
        msgError: 'Não foi possível atualizar os dados do usuário',
        msgSuccess: 'Dados do usuário atualizados com sucesso',
      },
      tabMenu: {
        titles: {
          tabOne: 'Dados Pessoais',
          tabTwo: 'Minha Senha',
        },
      },
    },
    userWithoutPermissionToAccessThisPlatform: 'Usuário sem permissão para acessar esta plataforma',
    noOptionsAvailable: 'Sem opções disponíveis',
  },
};
