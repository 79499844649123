export default {
  userLoggedIn(state: any) {
    return !(Object.keys(state.user).length === 0 && state.user.constructor === Object);
  },
  token: (state: any) => state.token,
  user: (state: any) => ({
    ...state.user,
    company: state.userCompany,
  }),
  firstAccess: (state: any) => state.firstAccess,
  permissions: (state: any) => state.permissions,
  masterId: (state: any) => (state.userCompany.masterId),
  acceptLanguage: (state: any) => state.acceptLanguage,
  isMaster: (state: any) => !!state.userCompany.masterId,
};
